<template>
    <loading-screen v-if="is_loading"/>
    <div v-else class="page-container">
        <main>
            <iframe v-if="file_type === 'xlsx' || file_type === 'doc' || file_type === 'docx' || file_type === 'ppt' || file_type === 'pptx'"
                :src="`https://view.officeapps.live.com/op/embed.aspx?src=${base_url}/${encodeURIComponent(document.attributes.path)}`">
            </iframe>

            <vue-pdf-app v-else-if="file_type === 'pdf' && pdfsrc" style="width: 100%; height: 100%;" :pdf="pdfsrc"></vue-pdf-app>

            <img v-else-if="file_type === 'png' || file_type === 'jpg' || file_type === 'jpeg'"
                 :src="`${base_url}/${document.attributes.path}`">

            <button class="download-button" @click="downloadDocument"><font-awesome-icon :icon="['far', 'download']"/></button>
        </main>
    </div>
</template>

<script>
import LoadingScreen from "../../components/LoadingScreen";
import VuePdfApp from 'vue-pdf-app';

export default {
    name: "documents-preview-page",
    components: {LoadingScreen, VuePdfApp},
    data() {
        return {
            document: null,
            base_url: process.env.VUE_APP_API_URL.slice(0, -7),
            file_type: null,
            pdfsrc: null,
            is_loading: false,
            is_downloading: false
        }
    },
    methods: {
        async getDocument() {
            this.is_loading = true;

            await this.$axios.get(`/public/documents/${this.$route.params.id}`)
                .then(async ({data}) => {
                    this.document = data.data;
                    this.file_type = data.data.attributes.attachment_name.split('.').pop();

                    if(this.file_type === 'pdf'){
                        await this.$axios.get(`/documents/${this.$route.params.id}/download`, {
                            responseType: 'blob',
                            headers: {
                                'Accept': 'application/pdf'
                            }
                        }).then(({data}) => {
                            let pdf = new Blob([data]);
                            const objectUrl = URL.createObjectURL(pdf);
                            this.pdfsrc = objectUrl;
                        });
                    }

                    this.is_loading = false;
                })
                .catch(e => {
                    this.is_loading = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_retrieve_document')),
                        type: 'error',
                    });
                });
        },
        downloadDocument() {
            this.is_downloading = true;

            this.$axios.get(`/public/documents/${this.$route.params.id}/download`, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                params: {
                    t: new Date().getTime()
                },
            }).then(({data}) => {
                this.is_downloading = false;

                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.document.attributes.attachment_name);
                document.body.appendChild(link);
                link.click();
            })
                .catch(e => {
                    this.is_downloading = false;
                });
        }
    },
    async mounted() {
        this.$route.params.id = this.$CryptoJS.AES.decrypt(atob(this.$route.params.id.toString()), 'preview').toString(this.$CryptoJS.enc.Utf8);
        await this.getDocument();
    },
    head() {
        return {
            title: {
                inner: this.$t('documents.document')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply h-full;

    main {
        @apply h-full flex;
        padding: 0 !important;

        iframe {
            @apply w-full h-full;
        }

        .download-button {
            @apply fixed rounded-full bg-primary shadow;
            right: 20px;
            bottom: 20px;

            @screen lg {
                right: 50px;
                bottom: 50px;
            }

            &:hover {
                @apply bg-primary-over;
            }

            & > svg {
                @apply text-white text-3xl m-4;
            }
        }
    }

}
</style>