<template>
    <loading-screen v-if="is_loading_user || is_loading"/>
    <div v-else class="page-container">
        <Headbar>
            <template v-slot:left>
                <div class="left-headbar-container">
                    <div class="back-container">
                        <button v-if="$route.params.role !== 'employee'" class="back-button" @click="$router.go(-1)">
                            <font-awesome-icon :icon="['fal', 'arrow-circle-left']"/>
                        </button>
                        <h1 v-if="user && user.attributes">{{ user.attributes.name }}</h1>
                    </div>
                    <div v-if="user && user.attributes" class="progress-bar-container">
                        <div class="progress-bar">
                            <div
                                :style="{ width: `${$route.params.role === 'trainer' ? user.attributes.progress.trainer_progress : user.attributes.progress.administrator_progress}%` }"
                                class="progress"/>
                        </div>
                        <p class="progress-text">{{
                                $route.params.role === 'trainer' ?
                                    user.attributes.progress.trainer_progress : user.attributes.progress.administrator_progress
                            }}%
                        </p>
                    </div>
                </div>
            </template>
            <template v-slot:right>


                <Search :placeholder="$t('documents.search_documents')" class="search-desktop" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('users.search_users')"
                            @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <div class="options-container">
                <div class="export-container-tablet">
                    <p class="export-as-text">{{ $t('export_as') }}</p>
                    <FormInputSelect v-model="export_option_selected" :allow-empty="false"
                                     :options="export_options" :placeholder="$t('documents.export_options')"
                                     className="filter" display-label="name"
                                     identifier="type"
                                     track-by="id">
                    </FormInputSelect>
                    <Button :class="{ spinner: is_printing }" :disabled="is_printing"
                            :onclick="generateDownload"
                            className="--grey --outline --match-select">
                        <font-awesome-icon :icon="['far', 'download']"/>
                    </Button>
                </div>
                <div class="filters-container" style="width: 1300px;">
                    <div class="filter-by-mobile" @click="filterByExpanded = !filterByExpanded">
                        <p class="filter-by-text">{{ $t('filter_by') }}</p>
                        <button class="btn-filter-by-mobile">
                            <font-awesome-icon v-if="filterByExpanded === true" :icon="['far', 'chevron-up']"/>
                            <font-awesome-icon v-else :icon="['far', 'chevron-down']"/>
                        </button>
                    </div>
                    <p class="filter-by-text">{{ $t('filter_by') }}</p>
                    <div v-if="filterByExpanded === true || windowWidth >= 768" class="filters-wrapper">
                        <FormInputSelect v-model="filter_type" :disabled="is_loading_types"
                                         :display-custom-label="(row) => `${row.attributes.name}`"
                                         :options="type_options"
                                         :placeholder="$t('documents.type')" className="filter" identifier="type"
                                         track-by="id" @input="onFilterUpdated">
                        </FormInputSelect>
                        <FormInputSelect v-model="filter_category" :disabled="is_loading_categories"
                                         :display-custom-label="(row) => `${row.attributes.name}`"
                                         :options="category_options" :placeholder="$t('documents.category')"
                                         className="filter"
                                         identifier="category" track-by="id"
                                         @input="onFilterUpdated">
                        </FormInputSelect>
                        <FormInputSelect v-model="filter_module" :disabled="is_loading_modules"
                                         :display-custom-label="(row) => `${row.attributes.name}`"
                                         :options="module_options" :placeholder="$t('documents.module')"
                                         className="filter"
                                         identifier="module" track-by="id"
                                         @input="onFilterUpdated">
                        </FormInputSelect>
                        <FormInputSelect v-model="filter_department" :disabled="is_loading_departments"
                                         :display-custom-label="(row) => `${row.attributes.name}`"
                                         :options="department_options" :placeholder="$t('documents.departmental_owner')"
                                         class="departmental-owner"
                                         className="filter" identifier="department_owner"
                                         track-by="id" @input="onFilterUpdated">
                        </FormInputSelect>
                        <FormInputSelect v-if="$route.params.role !== 'employee'" v-model="filter_trainers"
                                         :disabled="is_loading_trainers"
                                         :display-custom-label="(row) => `${row.attributes.name}`" :multiple="true"
                                         :options="trainer_options" :placeholder="$t('documents.trainer')"
                                         className="filter" identifier="trainer" track-by="id"
                                         @input="onTrainersFilterUpdated">
                        </FormInputSelect>
                        <FormInputSelect v-model="filter_training"
                                         :display-custom-label="(row) => `${row.name}`" :options="training_options"
                                         :placeholder="$t('documents.signature_status')"
                                         className="filter" identifier="training" track-by="id"
                                         @input="onFilterUpdated">
                        </FormInputSelect>
                        <Button :onclick="clearFilters" className="--primary --small">
                            {{ $t('clear_filters') }}
                        </Button>
                    </div>
                </div>

                <div class="export-container-normal">
                    <p class="export-as-text">{{ $t('export_as') }}</p>
                    <FormInputSelect v-model="export_option_selected" :allow-empty="false"
                                     :options="export_options" :placeholder="$t('documents.export_options')"
                                     className="filter" display-label="name"
                                     identifier="type"
                                     track-by="id">
                    </FormInputSelect>
                    <Button :class="{ spinner: is_printing }" :disabled="is_printing"
                            :onclick="generateDownload"
                            className="--grey --outline --match-select">
                        <font-awesome-icon :icon="['far', 'download']"/>
                    </Button>
                </div>
            </div>
            <vue-good-table ref="table" :columns="columns" :isLoading.sync="is_loading_documents" :pagination-options="{
    enabled: true,
    mode: 'records',
    dropdownAllowAll: false,
    perPage: 15,
    perPageDropdownEnabled: true,
    rowsPerPageLabel: $t('x_per_page', { x: $t('documents.documents') }),
}"
                            :rows="user_documents" :search-options="{
                    enabled: false,
                }" :sort-options="{
    enabled: true,
    multipleColumns: true,
}" :totalRows="totalRecords" mode="remote" styleClass="vgt-table vgt-custom vgt-documents"
                            @on-page-change="onPageChange" @on-per-page-change="onPerPageChange">
                <template slot="table-row" slot-scope="props">

                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button v-tippy="{ placement: 'top', arrow: true }" :content="$t('documents.copy_link')"
                                :onclick="() => copyLink(props.row.id)"
                                className="--secondary --outline --mini --big-text">
                            <font-awesome-icon :icon="['far', 'link']"/>
                        </Button>

                        <div v-if="!documentPreviewSupported(props.row.attributes.attachment_name)">
                            <Button v-tippy="{ placement : 'top',  arrow: true }"
                                    :content="$t('download')"
                                    :disabled="is_downloading"
                                    :onclick="()=>downloadDocument(props.row.id, props.row.attributes.attachment_name)"
                                    className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['far', 'download']"/>
                            </Button>
                        </div>

                        <Button v-if="documentPreviewSupported(props.row.attributes.attachment_name)"
                                v-tippy="{ placement : 'top',  arrow: true }"
                                :content="$t('documents.preview')"
                                :disabled="!documentPreviewSupported(props.row.attributes.attachment_name)"
                                :onclick="()=>previewDocument(props.row)"
                                className="--secondary --outline --mini --big-text">
                            <font-awesome-icon :icon="['far', 'external-link']"/>
                        </Button>

                        <!--Trainer Sign Button-->
                        <!--            <Button-->
                        <!--              v-if="($route.params.role === 'trainer' && trainerCanSign(props.row))"-->
                        <!--              :className="(trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text` : `&#45;&#45;primary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text`"-->
                        <!--              :onclick="() => toggleSign(props.row.id)"-->
                        <!--              :disabled="trainerSigned(props.row) || trainingSkipped(props.row)" :content="$t('sign')"-->
                        <!--              v-tippy="{ placement: 'top', arrow: true }">-->
                        <!--              <font-awesome-icon :icon="['far', 'signature']" />-->
                        <!--            </Button>-->

                        <!--Manager Sign Buttons-->
                        <!--            <div v-if="(($store.getters.hasRole('manager') && $route.params.role === 'manager'))">-->
                        <!--              <Button-->
                        <!--                :class="[-->
                        <!--                                   (trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text` : `&#45;&#45;primary &#45;&#45;mini &#45;&#45;big-text`,-->
                        <!--                                    props.row.attributes.signing_state === 'employee-signed' ? 'employee-signed' : '',-->
                        <!--                                    props.row.attributes.signing_state === 'trainer-signed' ? 'trainer-signed' : '',-->
                        <!--                                    props.row.attributes.signing_state === 'unsigned' ? '&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text' : '',-->
                        <!--                                    props.row.attributes.signing_state === 'signed' ? '&#45;&#45;primary &#45;&#45;mini &#45;&#45;big-text' : '',-->
                        <!--                                    props.row.attributes.signing_state === 'unsigneable' ? '&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text' : '',-->
                        <!--                                ]"-->
                        <!--                :disabled="checkIfSignIsDisabledManager(props.row.attributes.signing_state, props.row)">-->
                        <!--                <font-awesome-icon :icon="['far', 'signature']"/>-->
                        <!--              </Button>-->

                        <div v-if="(($store.getters.hasRole('manager') && $route.params.role === 'manager'))">
                            <Button
                                v-tippy="{ placement: 'top', arrow: true }"
                                :class="[(trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `--secondary --outline --mini --big-text` : `--primary --mini --big-text`,
                           props.row.attributes.signing_state === 'employee-signed' ? 'employee-signed' : '',
                           props.row.attributes.signing_state === 'trainer-signed' ? 'trainer-signed' : '',
                           props.row.attributes.signing_state === 'unsigned' ? '--secondary --outline --mini --big-text' : '',
                           props.row.attributes.signing_state === 'signed' ? '--primary --mini --big-text' : '',
                           props.row.attributes.signing_state === 'unsigneable' ? '--secondary --outline --mini --big-text' : '']"
                                :content="$t('sign')"
                                :disabled="checkIfSignIsDisabledManager(props.row.attributes.signing_state, props.row)"
                                :onclick="() => toggleSign(props.row.id)">
                                <font-awesome-icon :icon="['far', 'signature']"/>
                            </Button>
                        </div>
                        <!--                            <Button
                                                        v-if="(props.row.attributes.signing_state === 'employee-signed')"
                                                        class="employee-signed"
                                                        :className="(trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text` : `&#45;&#45;primary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text`">
                                                        <font-awesome-icon :icon="['far', 'signature']" />
                                                    </Button>

                                                    <Button
                                                        v-if="(props.row.attributes.signing_state === 'trainer-signed')"
                                                        class="trainer-signed"
                                                        :className="(trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text` : `&#45;&#45;primary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text`">
                                                        <font-awesome-icon :icon="['far', 'signature']" />
                                                    </Button>

                                                    <Button
                                                        v-if="(props.row.attributes.signing_state === 'unsigned')"
                                                        :className="`&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text`">
                                                        <font-awesome-icon :icon="['far', 'signature']" />
                                                    </Button>

                                                    <Button
                                                        v-if="(props.row.attributes.signing_state === 'signed')"
                                                        :className="`&#45;&#45;primary &#45;&#45;mini &#45;&#45;big-text`"
                                                        :disabled="trainerSigned(props.row) || trainingSkipped(props.row)">
                                                        <font-awesome-icon :icon="['far', 'signature']" />
                                                    </Button>

                                                    <Button
                                                        v-if="(props.row.attributes.signing_state === 'unsigneable')"
                                                        :className="`&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text`"
                                                        :content="$t('sign')" disabled>
                                                        <font-awesome-icon :icon="['far', 'signature']" />
                                                    </Button>-->
                        <!--                        v-if="(props.row.attributes.signing_state === 'employee-signed')"-->


                        <!--Trainer Sign Buttons-->
                        <div v-if="($route.params.role === 'trainer' && trainerCanSign(props.row))">
                            <Button
                                v-tippy="{ placement: 'top', arrow: true }"
                                :class="[
                                    (trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `--secondary --outline --mini --big-text` : `--primary --mini --big-text`,
                                    props.row.attributes.signing_state === 'employee-signed' ? 'employee-signed' : '',
                                    props.row.attributes.signing_state === 'trainer-signed' ? 'trainer-signed' : '',
                                    props.row.attributes.signing_state === 'unsigned' ? '--secondary --outline --mini --big-text' : '',
                                    props.row.attributes.signing_state === 'signed' ? '--primary --mini --big-text' : '',
                                    props.row.attributes.signing_state === 'unsigneable' ? '--secondary --outline --mini --big-text' : '',
                                ]"
                                :content="$t('sign')"
                                :disabled="checkIfSignIsDisabledTrainer(props.row.attributes.signing_state, props.row)"
                                :onclick="() => toggleSign(props.row.id)">
                                <font-awesome-icon :icon="['far', 'signature']"/>
                            </Button>
                        </div>

                        <!--Employee Sign Button-->
                        <div v-if="$route.params.role === 'employee'">
                            <Button
                                v-tippy="{ placement: 'top', arrow: true }"
                                :class="[
                                    (trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `--secondary --outline --mini --big-text` : `--primary --mini --big-text`,
                                    props.row.attributes.signing_state === 'employee-signed' ? 'employee-signed' : '',
                                    props.row.attributes.signing_state === 'trainer-signed' ? 'trainer-signed' : '',
                                    props.row.attributes.signing_state === 'unsigned' ? '--secondary --outline --mini --big-text' : '',
                                    props.row.attributes.signing_state === 'signed' ? '--primary --mini --big-text' : '',
                                    props.row.attributes.signing_state === 'unsigneable' ? '--secondary --outline --mini --big-text' : '',
                                ]"
                                :content="$t('sign')"
                                :disabled="checkIfSignIsDisabledEmployee(props.row.attributes.signing_state, props.row)"
                                :onclick="() => toggleSign(props.row.id)">
                                <font-awesome-icon :icon="['far', 'signature']"/>
                            </Button>
                        </div>

                        <!--Admin & Document Controller Sign Button-->
                        <div v-if="($store.getters.hasRole('administrator') && $route.params.role === 'administrator' && user.id !== $store.getters.user.id)
                        || ($store.getters.hasRole('document_controller') && $route.params.role === 'document_controller')">
                            <Button
                                v-tippy="{ placement: 'top', arrow: true }"
                                :class="[
                                    (trainerSigned(props.row) && !employeeSigned(props.row)) || !trainerSigned(props.row) ? `--secondary --outline --mini --big-text` : `--primary --mini --big-text`,
                                    props.row.attributes.signing_state === 'employee-signed' ? 'employee-signed' : '',
                                    props.row.attributes.signing_state === 'trainer-signed' ? 'trainer-signed' : '',
                                    props.row.attributes.signing_state === 'unsigned' ? '--secondary --outline --mini --big-text' : '',
                                    props.row.attributes.signing_state === 'signed' ? '--primary --mini --big-text' : '',
                                    props.row.attributes.signing_state === 'unsigneable' ? '--secondary --outline --mini --big-text' : '',
                                ]"
                                :content="$t('sign')"
                                :disabled="checkIfSignIsDisabledAdmin(props.row.attributes.signing_state, props.row)"
                                :onclick="() => toggleDocumentControllerSign(props.row.id)">
                                <font-awesome-icon :icon="['far', 'signature']"/>
                            </Button>
                        </div>
                        <!--                        <Button
                                                    v-else-if="($store.getters.hasRole('administrator') && $route.params.role === 'administrator' && user.id !== $store.getters.user.id)
                                                        || ($store.getters.hasRole('document_controller') && $route.params.role === 'document_controller')"
                                                    :className="(trainerSigned(props.row) && employeeSigned(props.row)) ? `&#45;&#45;primary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text` : `&#45;&#45;secondary &#45;&#45;outline &#45;&#45;mini &#45;&#45;big-text`"
                                                    :onclick="() => toggleDocumentControllerSign(props.row.id)"
                                                    :disabled="(trainerSigned(props.row) && employeeSigned(props.row)) || trainingSkipped(props.row)"
                                                    :content="$t('sign')" v-tippy="{ placement: 'top', arrow: true }">
                                                    <font-awesome-icon :icon="['far', 'signature']" />
                                                </Button>-->

                        <Button v-if="(($store.getters.hasRole('manager') && $route.params.role === 'manager')
                            || ($store.getters.hasRole('administrator') && $route.params.role === 'administrator')
                            || ($store.getters.hasRole('document_controller') && $route.params.role === 'document_controller'))
                            && !trainingSkipped(props.row)" v-tippy="{ placement: 'top', arrow: true }"
                                :content="$t('documents.not_required')"
                                :disabled="trainerSigned(props.row) && employeeSigned(props.row)"
                                :onclick="() => toggleManagerSkip(props.row.id)"
                                className="--secondary --outline --mini --big-text">
                            <font-awesome-icon :icon="['far', 'arrow-square-right']"/>
                        </Button>

                        <Button v-if="(($store.getters.hasRole('manager') && $route.params.role === 'manager')
                            || ($store.getters.hasRole('administrator') && $route.params.role === 'administrator')
                            || ($store.getters.hasRole('document_controller') && $route.params.role === 'document_controller'))
                            && trainingSkipped(props.row)" v-tippy="{ placement: 'top', arrow: true }"
                                :content="$t('documents.mark_as_required')"
                                :onclick="() => toggleManagerUnskip(props.row.id)"
                                className="--secondary --outline --mini --big-text">
                            <font-awesome-icon :icon="['far', 'arrow-square-left']"/>
                        </Button>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>

            <download-csv v-if="csv_data.length" v-show="false" ref="csv-generator" :data="csv_data"></download-csv>

            <vue-html2pdf ref="html2Pdf"
                          :enable-download="true"
                          :filename="`${user ? user.attributes.name : 'User'}'s ${$t('documents.induction_progress')}`"
                          :manual-pagination="true" :pdf-quality="2" pdf-content-width="1120px"
                          pdf-format="a4" pdf-orientation="landscape"
                          @beforeDownload="beforePDFDownload($event)" @hasDownloaded="hasPDFDownloaded($event)">
                <section slot="pdf-content" class="pdf-content">
                    <h1>{{ user ? user.attributes.name : 'User' }}'s {{ $t('documents.induction_progress') }} - {{
                            user ?
                                ($route.params.role === 'trainer' ? user.attributes.progress.trainer_progress :
                                    user.attributes.progress.administrator_progress) : 0
                        }}% {{ $t('documents.completion') }}</h1>

                    <vue-good-table v-for="(page, index) in printing_pages" :key="index" :columns="printing_columns"
                                    :pagination-options="{ enabled: true }" :rows="page" :totalRows="totalRecords"
                                    class="documents-table" styleClass="vgt-table vgt-printing-table vgt-custom">
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="html2pdf__page-break"/>
                        </template>
                    </vue-good-table>
                </section>
            </vue-html2pdf>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import FormGroupThree from "../../components/form/FormGroupThree";
import FormFileUpload from "../../components/form/FormFileUpload";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import Search from "../../components/Search";
import LoadingScreen from "../../components/LoadingScreen";
import DocumentsCreateModal from "@/components/documents/DocumentsCreateModal";
import SignDocumentModal from "@/components/documents/SignDocumentModal";
import DocumentControllerSignDocumentModal from "@/components/documents/DocumentControllerSignDocumentModal.vue";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";

export default {
    name: "users-update-page",
    components: {
        FormGroupTwo,
        FormInputText,
        FormInputSelect,
        FormInputDateTime,
        Button,
        SectionHeader,
        Form,
        Headbar,
        FormGroupThree,
        FormFileUpload,
        Search,
        LoadingScreen
    },
    data: function () {
        const columns = [
            {
                label: this.$t('documents.id'),
                field: 'id',
                sortable: false,
            },
            {
                label: this.$t('documents.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.type'),
                field: 'relationships.type.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.category'),
                field: 'relationships.category.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.language'),
                field: 'attributes.language',
                sortable: false,
            },
            {
                label: this.$t('documents.module'),
                field: 'relationships.module.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.version'),
                field: 'attributes.version',
                sortable: false,
            },
            {
                label: this.$t('documents.revision_details'),
                field: row => row.attributes.revision_details ? row.attributes.revision_details : '',
                sortable: false,
            },
            {
                label: this.$t('documents.next_review'),
                field: row => row.attributes.next_review_at !== null ? this.$moment(row.attributes.next_review_at).format('DD/MM/YYYY') : '',
                sortable: false,
            },
            {
                label: this.$t('documents.trainer'),
                field: row => this.returnTrainersWithLineManager(row),
                sortable: false,
            },
            {
                label: this.$t('documents.description'),
                field: row => row.attributes.description ? row.attributes.description : '',
                sortable: false,
            },
        ];

        const printing_columns = [
            {
                label: this.$t('documents.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.type'),
                field: 'relationships.type.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.category'),
                field: 'relationships.category.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.language'),
                field: 'attributes.language',
                sortable: false,
            },
            {
                label: this.$t('documents.module'),
                field: 'relationships.module.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('documents.version'),
                field: 'attributes.version',
                sortable: false,
            },
            {
                label: this.$t('documents.rev_details'),
                field: row => row.attributes.revision_details ? row.attributes.revision_details : '',
                sortable: false,
            },
            {
                label: this.$t('documents.next_review'),
                field: row => row.attributes.next_review_at ? this.$moment(row.attributes.next_review_at).format('DD/MM/YYYY') : '',
                sortable: false,
            },
            {
                label: this.$t('documents.trainer'),
                field: row => this.returnTrainersWithLineManager(row),
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update documents']))
            columns.push({
                label: this.$t('documents.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            printing_columns: printing_columns,
            printing_pages: [],
            user: null,
            user_documents: [],
            csv_data: [],
            totalRecords: 0,
            type_options: [],
            category_options: [],
            module_options: [],
            language_options: [],
            department_options: [],
            trainer_options: [],
            training_options: [{id: 1, name: 'Signed Employee Only', value: 'signed_employee'}, {id: 2, name: 'Signed Trainer Only', value: 'signed_trainer'}, {id: 3, name: 'Signed Both', value: 'signed_both'}, {id: 4, name: 'Signed None', value: 'signed_none'}],
            export_options: [{id: 1, name: 'CSV'}],
            export_option_selected: {id: 1, name: 'CSV'},
            serverParams: {
                per_page: 15,
            },
            filter_type: null,
            filter_category: null,
            filter_module: null,
            filter_department: null,
            filter_trainers: [],
            filter_training: null,
            is_loading: false,
            is_loading_user: false,
            is_loading_documents: false,
            is_loading_types: false,
            is_loading_categories: false,
            is_loading_modules: false,
            is_loading_languages: false,
            is_loading_departments: false,
            is_loading_trainers: false,
            is_downloading: false,
            is_saving: false,
            is_printing: false,
            is_loading_audits: false,
            is_loading_documents_csv_data: false,
            headbarExpanded: false,
            filterByExpanded: false,
            windowWidth: false,
            base_url: process.env.VUE_APP_API_URL.slice(0, -7),
        }
    },
    methods: {
        documentSigned(row) {
            if (this.user && this.user.relationships.documents.data.length)
                return (this.$route.params.role === 'trainer') && row?.attributes?.trainer_signed_at !== null
                    || this.$route.params.role === 'employee' && row?.attributes?.employee_signed_at !== null
                    || this.$store.getters.hasRole('administrator') && this.$store.getters.user.id === this.user.id && row?.attributes?.employee_signed_at !== null
        },
        createdAtFn(row) {
            return this.$moment(row.attributes.created_at).format('DD/MM/YYYY - HH:mm');
        },
        lastUpdatedAtFn(row) {
            return this.$moment(row.attributes.last_updated_at).format('DD/MM/YYYY - HH:mm');
        },
        trainerCanSign(row) {
            return row?.relationships?.job_roles?.data?.filter(job_role => _.find(this?.$store?.getters?.user?.relationships?.job_roles?.data, {id: job_role?.id}))?.length ? true : false;
        },
        trainerSigned(row) {
            return row?.attributes?.trainer_signed_at !== null
            // return _.find(this?.user?.relationships?.documents?.data, document => document.id === row.id)?.attributes?.trainer_signed_at ? true : false;
        },
        employeeSigned(row) {
            return row?.attributes?.employee_signed_at !== null
            // return _.find(this.user.relationships.documents.data, document => document.id === row.id).attributes.employee_signed_at ? true : false;
        },
        trainingSkipped(row) {
            // return row?.attributes?.skipped_at !== null

            return _.find(this?.user?.relationships?.documents?.data, document => document.id === row.id)?.attributes?.skipped_at ? true : false;
        },
        trainingMaterial(row) {
            return row?.attributes?.is_training_material

            // return _.find(this.user.relationships.documents.data, document => document.id === row.id).attributes.is_training_material ? true : false;
        },
        toUpperCaseWord(word) {
                return word.charAt(0).toUpperCase() + word.slice(1)
        },
        returnTrainersWithLineManager(row) {
            let allJobRoles = []

            if (row.attributes.is_line_manager) {
                let userJobRoles = this.user.relationships.job_roles.data.map(jr => this.toUpperCaseWord(jr.attributes.line_manager))
                allJobRoles = [...userJobRoles, ...allJobRoles]
            }

            let rowJobRoles = row.relationships.job_roles.data
            if (rowJobRoles) {
                rowJobRoles = rowJobRoles.map(jr => this.toUpperCaseWord(jr.attributes.name))
                allJobRoles = [...allJobRoles, ...rowJobRoles]
            }
            return allJobRoles.join(', ')
        },
        generateDownload() {
            if (!this.export_option_selected) return;

            if (this.export_option_selected.name === 'CSV')
                this.getCSVFromApi();
        },
        async getCSVFromApi() {
            this.is_printing = true
            this.$axios.get(`users/${this.$route.params.id}/documents/download`, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/csv',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                params: {
                    t: new Date().getTime()
                },
            })
                .then(({data}) => {
                    this.is_downloading = false;
                    const url           = window.URL.createObjectURL(new Blob([data]));
                    const link          = document.createElement('a');
                    link.href           = url;
                    link.setAttribute('download', 'report.csv');
                    document.body.appendChild(link);
                    link.click();
                    this.is_printing = false
                })
                .catch((err) => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_download_document')),
                        type: 'error',
                    });
                    this.is_printing = false
                })
        },
        async generateCSVDownload() {
            this.is_printing = true;
            await this.$refs['csv-generator'].generate();
            this.is_printing = false;
        },
        async generatePDFDownload() {
            this.is_printing = true;
            await this.$refs.html2Pdf.generatePdf()
        },
        async beforePDFDownload() {
            this.is_printing = true;
        },
        async hasPDFDownloaded() {
            this.is_printing = false;
        },
        async getUser() {
            this.is_loading_user = true;

            await this.$axios.get(`users/${this.$route.params.id}`)
                .then(({data}) => {
                    this.user            = data.data;
                    this.is_loading_user = false;
                })
                .catch(e => {
                    this.is_loading_user = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve_documents')),
                        type: 'error',
                    });
                });
        },
        async getDocuments() {
            this.getDocumentsCSVData();

            this.is_loading_documents = true;

            await this.$axios.get(`users/${this.$route.params.id}/documents`, {params: {...this.serverParams}})
                .then(({data}) => {
                    this.user_documents = data.data;
                    this.$refs.table.changePage(data.meta.current_page);
                    this.totalRecords = data.meta.total;

                    this.printing_pages               = [];
                    let temp_documents_printing_array = _.clone(this.user_documents);

                    while (temp_documents_printing_array.length > 0)
                        this.printing_pages.push(temp_documents_printing_array.splice(0, 8));

                    this.is_loading_documents = false;
                })
                .catch(e => {
                    this.is_loading_documents = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve_documents')),
                        type: 'error',
                    });
                });
        },
        getDocumentsCSVData() {
            this.is_loading_documents_csv_data = true;

            this.$axios.get(`users/${this.$route.params.id}/documents/list`, {params: this.serverParams})
                .then(({data}) => {
                    this.csv_data = [];

                    let documents = data.data;

                    documents.forEach(document => {
                        if (this.$route.params.role && this.$route.params.role === 'trainer') {
                            if (this.$store.getters.user.relationships.job_roles.data.filter(job_role => job_role.id === document.relationships.job_roles.data.id).length) {
                                this.csv_data.push({
                                    'Name': document.attributes.name,
                                    'Type': document.relationships.type.data && document.relationships.type.data.attributes ? document.relationships.type.data.attributes.name : '',
                                    'Category': document.relationships.category.data && document.relationships.category.data.attributes ? document.relationships.category.data.attributes.name : '',
                                    'Language': document.attributes.language,
                                    'Module': document.relationships.module.data && document.relationships.module.data.attributes ? document.relationships.module.data.attributes.name : '',
                                    'Date Created': this.$moment(document.attributes.created_at).format('YYYY-MM-DD HH:mm'),
                                    'Version': document.attributes.version,
                                    'Last Updated': this.$moment(document.attributes.updated_at).format('YYYY-MM-DD HH:mm'),
                                    'Review No.': document.attributes.review_count ? document.attributes.review_count : '',
                                    'Revision Details': document.attributes.revision_details ? document.attributes.revision_details : '',
                                    'Next Review': document.attributes.next_review_at ? this.$moment(document.attributes.next_review_at).format('YYYY-MM-DD HH:mm') : '',
                                    'Sites': document.relationships.sites.data.length ? document.relationships.sites.data.map(s => s.attributes.name).join(', ') : (''),
                                    'Site Groups': document.relationships.site_groups.data.length ? document.relationships.site_groups.data.map(sg => sg.attributes.name).join(', ') : (''),
                                    'Departmental Owner': document.relationships.department.data && document.relationships.department.data.attributes ? document.relationships.department.data.attributes.name : '',
                                    'Departments': document.relationships.departments.data.length ? document.relationships.departments.data.map(d => d.attributes.name).join(', ') : (''),
                                    'Trainer': document.attributes.is_line_manager ? (this.user.relationships.job_roles.data ? this.user.relationships.job_roles.data.map(jr => jr.attributes.line_manager.charAt(0).toUpperCase() + jr.attributes.line_manager.slice(1)).join(', ') : '') : (document.relationships.job_roles.data && document.relationships.job_roles.data.attributes ? document.relationships.job_roles.data.attributes.name : ''),
                                    'Reference': document.attributes.reference ? document.attributes.reference : '',
                                    'Description': document.attributes.description ? document.attributes.description : '',
                                });
                            }
                        } else {
                            this.csv_data.push({
                                'Name': document.attributes.name,
                                'Type': document.relationships.type.data && document.relationships.type.data.attributes ? document.relationships.type.data.attributes.name : '',
                                'Category': document.relationships.category.data && document.relationships.category.data.attributes ? document.relationships.category.data.attributes.name : '',
                                'Language': document.attributes.language,
                                'Module': document.relationships.module.data && document.relationships.module.data.attributes ? document.relationships.module.data.attributes.name : '',
                                'Date Created': this.$moment(document.attributes.created_at).format('YYYY-MM-DD HH:mm'),
                                'Version': document.attributes.version,
                                'Last Updated': this.$moment(document.attributes.updated_at).format('YYYY-MM-DD HH:mm'),
                                'Review No.': document.attributes.review_count ? document.attributes.review_count : '',
                                'Revision Details': document.attributes.revision_details ? document.attributes.revision_details : '',
                                'Next Review': document.attributes.next_review_at ? this.$moment(document.attributes.next_review_at).format('YYYY-MM-DD HH:mm') : '',
                                'Sites': document.relationships.sites.data.length ? document.relationships.sites.data.map(s => s.attributes.name).join(', ') : (''),
                                'Site Groups': document.relationships.site_groups.data.length ? document.relationships.site_groups.data.map(sg => sg.attributes.name).join(', ') : (''),
                                'Departmental Owner': document.relationships.department.data && document.relationships.department.data.attributes ? document.relationships.department.data.attributes.name : '',
                                'Departments': document.relationships.departments.data.length ? document.relationships.departments.data.map(d => d.attributes.name).join(', ') : (''),
                                'Trainer': document.attributes.is_line_manager ? (this.user.relationships.job_roles.data ? this.user.relationships.job_roles.data.map(jr => jr.attributes.line_manager.charAt(0).toUpperCase() + jr.attributes.line_manager.slice(1)).join(', ') : '') : (document.relationships.job_roles.data && document.relationships.job_roles.data.attributes ? document.relationships.job_roles.data.attributes.name : ''),
                                'Reference': document.attributes.reference ? document.attributes.reference : '',
                                'Description': document.attributes.description ? document.attributes.description : '',
                            });
                        }
                    })

                    this.is_loading_documents_csv_data = false;
                })
                .catch(e => {
                    this.is_loading_documents_csv_data = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('documents.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async copyLink(document_id) {
            let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(document_id.toString(), 'download').toString());

            let url = `${location.origin}/documents/${encrypted_id}/download`;

            await navigator.clipboard.writeText(url);

            this.$notify({
                title: this.$t('success'),
                text: this.$t('documents.link_copied'),
                type: 'success',
            });
        },
        documentPreviewSupported(document) {
            let file_type = document.split('.').pop();

            return file_type === 'jpg' || file_type === 'jpeg' || file_type === 'png' || file_type === 'doc' || file_type === 'docx'
                || file_type === 'ppt' || file_type === 'pptx' || file_type === 'xlsx' || file_type === 'pdf';
        },
        async previewDocument(document) {
            let file_type = document.attributes.attachment_name.split('.').pop();

            let encrypted_id = btoa(this.$CryptoJS.AES.encrypt(document.id.toString(), 'preview').toString());

            let url = `${location.origin}/documents/${encrypted_id}/preview`;

            window.open(url, '_blank');
        },
        downloadDocument(document_id, attachment_name) {
            this.is_downloading = true;

            this.$axios.get(`/documents/${document_id}/download`, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                params: {
                    t: new Date().getTime()
                },
            }).then(({data}) => {
                this.is_downloading = false;

                const url  = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href  = url;
                link.setAttribute('download', attachment_name);
                document.body.appendChild(link);
                link.click();
            });
        },
        onFilterUpdated() {
            const filters = [];
            let filter    = null;

            if (this.filter_type)
                filters.push({filter_by: 'type_id', filter_value: this.filter_type.id});

            if (this.filter_category)
                filters.push({filter_by: 'category_id', filter_value: this.filter_category.id});

            if (this.filter_module)
                filters.push({filter_by: 'module_id', filter_value: this.filter_module.id});

            if (this.filter_department)
                filters.push({filter_by: 'department_id', filter_value: this.filter_department.id});

            if (this.filter_training) filter = this.filter_training.value;

            if (filters.length)
                this.updateParams({
                    filters: filters
                });
            else this.removeParam('filters');

            if (filter) this.updateParams({filter: filter});
            else this.removeParam('filter');

            this.getDocuments();
        },
        onTrainersFilterUpdated() {
            let job_roles = [];

            job_roles = this.filter_trainers.map(ft => ft.id);

            if (job_roles.length)
                this.updateParams({
                    job_roles: job_roles
                });
            else this.removeParam('job_roles');

            this.getDocuments();
        },
        clearFilters() {
            this.filter_type       = null;
            this.filter_category   = null;
            this.filter_module     = null;
            this.filter_department = null;
            this.filter_trainers   = [];
            this.filter_training   = null;

            this.updateParams({filters: []});
            this.removeParam('job_roles');

            this.getDocuments();
        },
        toggleSign(document_id) {
            this.$modal.show(
                SignDocumentModal, {
                    document_id: document_id,
                },
                {
                    name: 'sign-document-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.getUser();
                            if (e.params && e.params?.currentPage) this.updateParams({page: e.params.currentPage});
                            this.getDocuments();
                        }
                    }
                }
            );
        },
        checkIfSignIsDisabledTrainer(signingState, rowProps) {
            if (signingState === 'signed') return true
            else if (signingState === 'unsigned') return (this.trainerSigned(rowProps) || this.trainingSkipped(rowProps))
            else if (signingState === 'unsigneable') return true
            else if (signingState === 'trainer-signed') return (this.trainerSigned(rowProps) || this.trainingSkipped(rowProps))
            else if (signingState === 'employee-signed') return (this.trainerSigned(rowProps) || this.trainingSkipped(rowProps))
        },
        checkIfSignIsDisabledManager(signingState, rowProps) {
            if (signingState === 'signed') return true
            else if (signingState === 'unsigned') return ((this.trainerSigned(rowProps) && this.employeeSigned(rowProps)) || this.trainingSkipped(rowProps))
            else if (signingState === 'unsigneable') return true
            else if (signingState === 'trainer-signed') return ((this.trainerSigned(rowProps) && this.employeeSigned(rowProps)) || this.trainingSkipped(rowProps))
            else if (signingState === 'employee-signed') return ((this.trainerSigned(rowProps) && this.employeeSigned(rowProps)) || this.trainingSkipped(rowProps))
        },
        checkIfSignIsDisabledEmployee(signingState, rowProps) {
            if (signingState === 'signed') return true
            else if (signingState === 'unsigned') return (this.documentSigned(rowProps) || this.trainingSkipped(rowProps))
            else if (signingState === 'unsigneable') return true
            else if (signingState === 'trainer-signed') return (this.documentSigned(rowProps) || this.trainingSkipped(rowProps))
            else if (signingState === 'employee-signed') return (this.documentSigned(rowProps) || this.trainingSkipped(rowProps))
        },
        checkIfSignIsDisabledAdmin(signingState, rowProps) {
            if (signingState === 'signed') return true
            else if (signingState === 'unsigned') return ((this.trainerSigned(rowProps) && this.employeeSigned(rowProps)) || this.trainingSkipped(rowProps))
            else if (signingState === 'unsigneable') return true
            else if (signingState === 'trainer-signed') return ((this.trainerSigned(rowProps) && this.employeeSigned(rowProps)) || this.trainingSkipped(rowProps))
            else if (signingState === 'employee-signed') return ((this.trainerSigned(rowProps) && this.employeeSigned(rowProps)) || this.trainingSkipped(rowProps))
        },
        toggleDocumentControllerSign(document_id,) {
            this.$modal.show(
                DocumentControllerSignDocumentModal, {
                    document_id: document_id,
                    role: this.$store.getters.hasRole('administrator') ? 'administrator' : 'document_controller',
                    trainer_signed_at: _.find(this.user.relationships.documents.data, document => document.id === document_id).attributes.trainer_signed_at,
                    employee_signed_at: _.find(this.user.relationships.documents.data, document => document.id === document_id).attributes.employee_signed_at,
                },
                {
                    name: 'document-controller-sign-document-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': async (e) => {
                        if (e.params === true) {
                            await this.getUser();
                            await this.getDocuments();
                        }
                    }
                }
            );
        },
        toggleManagerSkip(document_id) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('documents.not_required'),
                    message: this.$t('documents.are_you_sure_skip'),
                    confirmText: this.$t('documents.not_required'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                },
                {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$axios.post(`documents/${document_id}/skip`, {role: this.$route.params.role, user_id: this.$route.params.id})
                                .then(({data}) => {
                                    this.getUser();
                                    this.getDocuments();
                                })
                                .catch(e => {
                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('documents.error_skip')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleManagerUnskip(document_id) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('documents.mark_as_required'),
                    message: this.$t('documents.are_you_sure_required'),
                    confirmText: this.$t('confirm'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                },
                {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$axios.post(`documents/${document_id}/un-skip`, {role: this.$route.params.role, user_id: this.$route.params.id})
                                .then(({data}) => {
                                    this.getUser();
                                    this.getDocuments();
                                })
                                .catch(e => {
                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('documents.error_unskip')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm, page: 1});
            else this.removeParam('term');

            this.getDocuments();
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getDocuments();
        },
        onPerPageChange(params) {
            this.updateParams({per_page: params.currentPerPage});
            this.getDocuments();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        async getTypes() {
            this.is_loading_types = true;

            await this.$axios.get('types/list')
                .then(({data}) => {
                    this.type_options     = data.data;
                    this.is_loading_types = false;
                })
                .catch(e => {
                    this.is_loading_types = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('types.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getCategories() {
            this.is_loading_categories = true;

            await this.$axios.get('categories/list')
                .then(({data}) => {
                    this.category_options      = data.data;
                    this.is_loading_categories = false;
                })
                .catch(e => {
                    this.is_loading_categories = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('categories.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getModules() {
            this.is_loading_modules = true;

            this.$axios.get('modules/list')
                .then(({data}) => {
                    this.module_options     = data.data;
                    this.is_loading_modules = false;
                })
                .catch(e => {
                    this.is_loading_modules = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('modules.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getDepartments() {
            this.is_loading_departments = true;

            this.$axios.get('departments/list')
                .then(({data}) => {
                    this.department_options     = data.data;
                    this.is_loading_departments = false;
                })
                .catch(e => {
                    this.is_loading_departments = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('departments.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getTrainers() {
            this.is_loading_trainers = true;

            this.$axios.get('jobRoles/list')
                .then(({data}) => {
                    this.trainer_options     = data.data;
                    this.is_loading_trainers = false;
                })
                .catch(e => {
                    this.is_loading_trainers = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('trainers.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
    },
    async mounted() {
        this.$route.params.id = this.$CryptoJS.AES.decrypt(atob(this.$route.params.id.toString()), 'user').toString(this.$CryptoJS.enc.Utf8);

        if (!this.$route.params.id) {
            this.$router.push('/');
            return;
        }

        this.windowWidth = window.innerWidth;

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        })

        this.is_loading = true;
        await Promise.all([this.getTypes(), this.getCategories(), this.getModules(), this.getTrainers(), this.getDepartments()]);
        this.is_loading = false;

        if (this.$route.params.role && this.$route.params.role === 'trainer')
            this.updateParams({role: 'trainer', is_training_material: 1});

        await this.getUser();
        await this.getDocuments();
    },
    head() {
        return {
            title: {
                inner: this.$t('users.user')
            },
        }
    }
}
</script>

<style lang="scss">
.page-container {
    .headbar-container {
        @apply items-start;

        @screen md {
            @apply items-center;
        }

        .left {
            @apply w-auto flex-grow;
        }

        .right {

            .btn-search-mobile {
                @apply my-auto;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.page-container {

    .left-headbar-container {
        @apply flex flex-col w-full gap-y-4;

        @screen lg {
            @apply flex-row gap-x-4 items-center;
        }

        .back-container {
            @apply flex flex-row gap-x-4 items-center;

            .back-button {
                @apply text-2xl text-grey;
            }
        }

        .progress-bar-container {
            @apply w-full h-3 flex flex-row justify-evenly items-center my-4 mx-auto max-w-md;
            width: 110%;

            @screen md {
                @apply ml-0 w-3/4;
            }

            @screen lg {
                @apply w-1/2 my-auto;
            }

            @screen 2xl {
                @apply w-1/3 ml-8 my-auto;
            }

            .progress-bar {
                @apply w-3/4 h-3 bg-primary bg-opacity-20 rounded-xl relative mr-auto;

                .progress {
                    @apply absolute h-3 bg-primary rounded-xl;
                }
            }

            .progress-text {
                @apply ml-2 mb-0.5 text-grey mr-auto;
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile,
    .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active,
        &:focus {
            @apply outline-none;
        }
    }

    .search-desktop,
    .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .options-container {
        @apply flex flex-col mb-6 w-full gap-y-4 flex-wrap;

        @screen 2xl {
            @apply flex-row gap-x-4 items-center justify-between;
        }

        .export-container-tablet {
            @apply flex flex-row gap-x-4 items-center hidden;

            @screen md {
                @apply flex;
            }

            @screen 2xl {
                @apply hidden;
            }

            & > .export-as-text {
                @apply ml-auto text-sm font-bold text-grey;
                width: max-content;
            }

            & > .input-group {
                @apply mb-0 w-1/2;
                max-width: 160px;
            }
        }

        .documents-title-normal {
            @apply font-bold text-2xl capitalize pr-8 block text-grey;

            @screen md {
                @apply hidden;
            }

            @screen 2xl {
                @apply block;
            }
        }

        .filters-container {
            @apply flex flex-col items-center w-full;

            @screen md {
                @apply flex-row flex-nowrap gap-x-4;
            }

            @screen 2xl {
                @apply w-8/12;
            }

            .filters-wrapper {
                @apply flex flex-row flex-wrap gap-y-4 mt-4;

                @screen md {
                    @apply mt-0 w-full;
                }

                .departmental-owner {
                    @screen md {
                        min-width: 200px !important;
                    }
                }

                .input-group {
                    @apply w-1/2 mb-0;

                    @screen md {
                        @apply w-auto gap-y-0 gap-x-4 flex-grow;
                        max-width: 20%;
                        min-width: 10rem;
                    }

                    &:nth-of-type(even) {
                        @apply pl-2;

                        @screen md {
                            @apply pr-2;
                        }

                    }

                    &:nth-of-type(odd) {
                        @apply pr-2;

                        @screen md {
                            @apply pl-2;
                        }

                    }
                }
            }

            .filter-by-mobile {
                @apply flex flex-row w-full justify-between cursor-pointer font-bold text-grey text-sm;

                @screen md {
                    @apply hidden;
                }
            }

            & > .filter-by-text {
                @apply w-full hidden;

                @screen md {
                    @apply w-auto block text-grey text-sm font-bold;
                    min-width: max-content;
                }
            }

        }

        .export-container-normal {
            @apply flex flex-row w-full gap-x-4 items-center;

            @screen md {
                @apply hidden;
            }

            @screen 2xl {
                @apply flex w-2/12;
            }

            .export-as-text {
                @apply font-bold text-grey text-sm;
                min-width: max-content;

                @screen md {
                    @apply ml-auto;
                }
            }

            .input-group {
                @apply mb-0 w-2/4;
                min-width: 4.5rem;

                @screen md {
                    @apply w-auto;
                }
            }
        }
    }
}

.pdf-content {
    @apply w-full px-4;

    & > h1 {
        @apply text-grey font-bold text-2xl mt-6 mb-10;
    }
}

.employee-signed {
    background: linear-gradient(to right, #00A18B 50%, white 50%) !important;
}

.trainer-signed {
    background: linear-gradient(to left, #00A18B 50%, white 50%) !important;
}
</style>
